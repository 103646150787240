@font-face {
  font-family: "RB";
  src: url("./fonts/RB-Regular.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "RB";
  src: url("./fonts/RB-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "RB", sans-serif;
  font-weight: 700;
  line-height: 1.4;
}

body {
  font-family: "RB", sans-serif;
  font-weight: 300;
}

.text-mobasher {
  color: #136d6b;
}

.btn-mobasher {
  background-color: #136d6b;
  color: #fff;
}

.btn-mobasher:hover {
  background-color: #0e5655;
  color: #fff;
}

nav h1 {
  line-height: 1;
}

.support-btn {
  width: 42px;
  height: 42px;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

#hero {
  background: #136d6b url(./images/riyadh-city.webp) no-repeat center center;
  background-size: cover;
  min-height: 475px;
  animation: pulse-bg 20s infinite ease-in-out;
}

@keyframes pulse-bg {
  0%,
  100% {
    background-size: 100%;
  }
  50% {
    background-size: 150%;
  }
}

#hero .btn-group {
  bottom: 5% !important;
}

#hero .btn-group > .btn {
  border-radius: 1rem;
}

#hero .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

#hero .btn-group > :not(.btn-check) + .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

@media screen and (min-width: 992px) {
  #hero .btn-group {
    width: 50%;
  }
  @keyframes pulse-bg {
    0%,
    100% {
      background-size: 112%;
    }
    50% {
      background-size: 200%;
    }
  }
}

@media screen and (min-width: 1200px) {
  #hero {
    min-height: 525px;
  }
  #hero .btn-group {
    bottom: 8% !important;
  }
}

@media screen and (min-width: 1400px) {
  #hero {
    min-height: 550px;
  }
  #hero .btn-group {
    bottom: 12% !important;
  }
}

@media screen and (max-width: 991px) {
  #hero .btn-group {
    width: 75%;
  }
  @keyframes pulse-bg {
    0%,
    100% {
      background-size: 150%;
    }
    50% {
      background-size: 250%;
    }
  }
}

@media screen and (max-width: 767px) {
  #hero {
    min-height: 425px;
    background-size: inherit;
  }
  @keyframes pulse-bg {
    0%,
    100% {
      background-size: 230%;
    }
    50% {
      background-size: 350%;
    }
  }
}

@media screen and (max-width: 576px) {
  #hero .btn-group {
    bottom: 0 !important;
  }
  #hero .btn-group {
    width: 100%;
  }
}

@media screen and (max-width: 415px) {
  @keyframes pulse-bg {
    0%,
    100% {
      background-size: 270%;
    }
    50% {
      background-size: 350%;
    }
  }
}

.curve {
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: auto;
}

#about ul {
  list-style: unset;
}

#services {
  background: #136d6b url(./images/bg-lines.svg) no-repeat right center;
}

#add-auction-card {
  background: #136d6b url(./images/bg-lines.svg) no-repeat right center;
  border-radius: 15px;
}

@media screen and (max-width: 992px) {
  #add-auction-card {
    max-height: 700px;
  }
}

@media screen and (max-width: 768px) {
  #add-auction-card {
    max-height: 600px;
  }
}

#footer {
  background: #136d6b url(./images/bg-footer.svg) no-repeat center 62%;
}

#footer .border-top {
  border-color: rgba(255, 255, 255, 0.1) !important;
}

.custom-card {
  background-color: #168382;
  border-radius: 10px;
  padding: 20px;
}
